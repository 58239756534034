.navbarMenu {
  background-color: inherit;

  @include breakpoint('large+') {
    margin-top: $header-h;

    .page-search & {
      margin-top: 0;
    }
  }
}

.navbarMenu__pages {
  margin: 0;
  padding: var(--spacing-05) var(--spacing-06) var(--spacing-14) var(--spacing-06);
  counter-reset: chapter;

  @include breakpoint('small-') {
    padding-right: var(--spacing-05);
    padding-left: var(--spacing-05);
  }

  a {
    @include f-body-02;
    position: relative;
    display: flex;
    margin-top: var(--spacing-04);
    padding-left: var(--spacing-03);

    .page--hide-numbers & {
      padding-left: var(--spacing-05);
    }

    mark {
      color: inherit;
    }
  }

  a:active,
  a:hover {
    outline-width: 0;
    border-color: transparent;
  }

  > li {
    margin-top: var(--spacing-06);

    &:first-child {
      margin-top: 0;
    }
  }

  > li > a {
    padding-left: var(--spacing-06);

    .page--hide-numbers & {
      padding-left: 0;
    }

    &::before {
      content: counter(chapter) " ";
      counter-increment: chapter;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      padding-right: var(--spacing-03);

      .page--hide-numbers & {
        display: none;
      }
    }
  }

  .link--no-accessible {
    opacity: .2;
    pointer-events: none;
  }

  .navbarMenu__context {
    @include f-caption;
    display: block;
    color: var(--color__text--light);
  }

  /* Modifiers */

  &--home {
    > li > a {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}

.navbarMenu__subpages {
  max-height: 0;
  margin-bottom: calc(var(--spacing-3) * -1);
  visibility: hidden;
  overflow: hidden;
  transition: max-height 0.25s linear,  padding 0.25s linear, visibility 0s 0.25s;
}

a[aria-expanded="true"] + {
  .navbarMenu__subpages {
    max-height: (50px * 3) + 12px;
    visibility: visible;
    transition: max-height 0.25s linear, padding 0.25s linear;
  }

  .navbarMenu__subpages--0 {
    max-height: (50px * 3) + 12px;
  }

  @for $i from 1 through 20 { /* works with up to 3 * 20 pages per section which should be enough */
    .navbarMenu__subpages--#{$i} {
      max-height:#{((50px * 3) * ($i + 1)) + 12px};
    }
  }
}

/* Table of content opened */
.navbarMenu__pages--toc {
  .navbarMenu__subpages {
    max-height: (50px * 3) + 12px;
    padding-bottom: var(--spacing-03);
    visibility: visible;
    transition: max-height 0.25s linear, padding 0.25s linear;
  }

  .navbarMenu__subpages--0 {
    max-height:(50px * 3) + 12px;
  }

  @for $i from 1 through 20 { /* works with up to 3 * 20 pages per section which should be enough */
    .navbarMenu__subpages--#{$i} {
      max-height:#{((50px * 3) * ($i + 1)) + 12px};
    }
  }
}

.navbarMenu__pages ul {
  padding-left: var(--spacing-06);

  .page--hide-numbers & {
    padding-left: 0;
  }
}

.navbarMenu__guides {
  position: relative;
  background-color: inherit;
  border-bottom: 1px solid var(--color__border);
  z-index: $z-index-navbar;

  ul li:last-child {
    margin-bottom: var(--spacing-06);
  }

  button + ul {
    top: $header-h-small;

    @include breakpoint('large+') {
      top: $header-h;
    }
  }

  @include breakpoint('medium-') {
    position: sticky;
    top: 0;
    padding-left: var(--spacing-05);
    padding-right: var(--spacing-05);
  }

  @include breakpoint('large+') {
    margin: 0 var(--spacing-06);
  }
}

.navbarMenu__toggle {
  @include btn-reset;
  @include f-body-02-em;
  position: relative;
  box-sizing: border-box;
  padding-top: var(--spacing-05);
  padding-right: var(--spacing-06);
  padding-bottom: calc(var(--spacing-05) - 1px);
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  transition: color 0.3s ease;

  .navbarMenu__guides--with-subpages & {
    padding-right: var(--spacing-10);
  }

  &:not(span) {
    cursor: pointer;

    &:hover {
      color: var(--color__text--light);
    }
  }

  @include breakpoint('small-') {
    padding-right: var(--spacing-10);

    .navbarMenu__guides--with-subpages & {
      padding-right: var(--spacing-13);
    }
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-06);
    padding-bottom: calc(var(--spacing-06) - 1px);
  }
}

.navbarMenu__close {
  @include btn-reset;
  position: absolute;
  top: var(--spacing-05);
  right: var(--spacing-06);
  z-index: 2;

  @include breakpoint('small-') {
    right: var(--spacing-05);
  }

  @include breakpoint('large+') {
    display: none;
  }
}

.navbarMenu__toggleIcon,
.navbarMenu__tocIcon {
  position: absolute;
  right: 0;
  top: var(--spacing-05);
  margin-left: var(--spacing-02);

  @include breakpoint('medium-') {
    right: var(--spacing-10);
  }

  @include breakpoint('large+') {
    top: var(--spacing-06);
  }
}

// button[aria-expanded="true"] .navbarMenu__tocIcon,
// button:hover .navbarMenu__tocIcon,
// .navbarMenu__tocIcon:hover {
//   color: var(--color__text);
// }

.navbarMenu__toggleIcon {
  top:-1px;
  transition: transform .25s linear;
  transform: rotate(0);
}

.navbar--expanded .navbarMenu__toggleIcon,
.navbarMenu__guides--active .navbarMenu__toggleIcon {
  transform: rotate(180deg);
}

.navbarMenu__guides li a,
.navbarMenu__toggle {
  display: block;
  width: 100%;
  text-align: left;
}

.navbarMenu__guides li a {
  @include f-body-02;
  margin-top: var(--spacing-06);
}

.navbarMenu__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: var(--color__overlay);
  opacity: 0;
  visibility: hidden;
  z-index: $z-index-navbar - 1;
  cursor: pointer;
  transition: opacity 0.35s ease, visibility 0s 0.35s;
}

// @include breakpoint('xsmall') {
//   .navbarMenu__guides--active {
//     ul {
//       max-height: calc(100vh - #{$header-h-small});

//       @include breakpoint('small+') {
//         max-height: calc(100vh - #{$header-h});
//       }
//     }
//   }

//   .navbar--home,
//   .navbar--search {
//     .navbarMenu__guides--active ul {
//       max-height: 9999em;
//     }
//   }

//   .navbarMenu__guides--active + .navbarMenu__overlay {
//     visibility: visible;
//     opacity: 1;
//     transition: opacity 0.35s;
//   }
// }

// @include breakpoint('medium-') {
//   .navbar--home {
//     .navbarMenu__guides--active ul {
//       max-height: 9999em;
//     }
//   }
// }

/* No numbers in post search results */
.searchResults .navbarMenu__pages > li > a {
  display: block;
  padding-left: 0;

  &:before {
    display: none;
  }
}
