.media-carousel {
  position: relative;
}

.media-carousel__item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  .media__caption {
    display: none;
  }

  &.is-active {
    position: relative;
    z-index: 1;
    opacity: 1;
    pointer-events: auto;

    .media__caption {
      display: block;
    }
  }

  .media-carousel--effect-fade & {
    transition: opacity 300ms linear 400ms;

    &.is-active {
      transition: opacity 600ms ease-in;
    }
  }
}
