[data-behavior='softVideo'] {
  display: block;
  transition: opacity 250ms ease-in-out;
  max-width: 100%;
  width: 100%;

  &:not(.s-fade-in),
  &.s-hidden {
    opacity: 0;
  }

  &.s-fade-in:not(.s-hidden) {
    opacity: 1;
  }
}

.btn-video {
  position: absolute;
  bottom: rem-calc(16);
  right: rem-calc(16);
  left: auto;
  border-radius: 100%;
  background: var(--color__background--ultra-light-aplha);
  width: rem-calc(40);
  height: rem-calc(40);
  border: none;
  color: var(--color__background--dark);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease;
  cursor: pointer;

  svg {
    display: block;
    width: rem-calc(20);
    height: rem-calc(20);
  }

  &:hover {
    background: var(--color__background);
  }

  &.btn-video--center {
    top: 50%;
    left: 50%;
    width: rem-calc(60);
    height: rem-calc(60);
    transform: translateX(-50%) translateY(-50%);

    svg {
      width: rem-calc(30);
      height: rem-calc(30);
    }
  }
}

[data-behavior='toggleVideoSound'] {
  right: rem-calc(64);
}


