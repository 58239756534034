/* Font face */

@font-face {
    font-family: "Suisse";
    src: url("/dist/fonts/suisse/SuisseIntl-Regular.woff2") format("woff2"),
        url("/dist/fonts/suisse/SuisseIntl-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Suisse";
    src: url("/dist/fonts/suisse/SuisseIntl-Regular-Italic.woff2") format("woff2"),
        url("/dist/fonts/suisse/SuisseIntl-Regular-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Suisse";
    src: url("/dist/fonts/suisse/SuisseIntl-Medium.woff2") format("woff2"),
        url("/dist/fonts/suisse/SuisseIntl-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Suisse";
    src: url("/dist/fonts/suisse/SuisseIntl-SemiBold.woff2") format("woff2"),
        url("/dist/fonts/suisse/SuisseIntl-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/***
  Variables for font families.
***/

$sans-serif-font: "Suisse", 'Arial', sans-serif;
