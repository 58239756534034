/*
  Colors by shade.
*/

:root {
  --color__white: #fff;
  --color__black: #000;
  --color__black--54: #757575;
  --color__black--20: #CCC;
  --color__black--15: #D8D8D8;
  --color__black--10: #E5E5E5;
  --color__black--05: #F2F2F2;
  --color__black--03: #F7F7F7;
  --color__black--a-75: rgba(0, 0, 0, .75);
  --color__grey--a-97: rgba(242, 242, 242, .97);
  --color__blue: #0048B8;
  --color__yellow: #FFFF00;
}


/*
  Colors by usage.
*/

:root {
  --color__background: var(--color__white);
  --color__background--dark: var(--color__black);
  --color__background--medium: var(--color__black--15);
  --color__background--light: var(--color__black--05);
  --color__background--ultra-light-aplha: rgba(255, 255, 255, 0.5);
  --color__background--ultra-light: var(--color__black--03);
  --color__background--error: var(--color__yellow);
  --color__text: var(--color__black);
  --color__text--on-dark: var(--color__white);
  --color__text--light: var(--color__black--54);
  --color__text--highlight: var(--color__yellow);
  --color__text--placeholder: var(--color__black--20);
  --color__border: var(--color__black--10);
  --color__brand: var(--color__blue);
  --color__overlay: var(--color__black--a-75);
  --color__overlay--light: var(--color__grey--a-97);
}
