$margin-top__h1: (
  'xsmall' : 100,
  'small+' : 150
);

$margin-top__h2: (
  'xsmall' : 100,
  'small+' : 150
);

$margin-top__h3: (
  'xsmall' : 80,
  'small+' : 120
);

$margin-top__h4: (
  'xsmall' : 80,
  'small+' : 120
);

$margin-top__intro: (
  'xsmall' : 80,
  'small+' : 120
);

$margin-top__h5: (
  'xsmall' : 50,
  'small+' : 60
);

$margin-top__body: (
  'xsmall' : 50,
  'small+' : 60
);

$margin-top__list: (
  'xsmall' : 50,
  'small+' : 60
);

$margin-top__results: (
  'xsmall' : 20,
  'small+' : 30
);

.post {
  @include f-body-01;
  position: relative;
}

.post__wrapper {
  margin-right: auto;
  margin-left: auto;

  &::before {
    content: ' ';
    position: relative;
    height: 0px;
    width: 100%;
    overflow: hidden;
    white-space: pre;
    display: block;
  }

  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      @if (map-get($main-col-post-widths, $name) == 'fluid') {
        width: auto;
        padding-right: map-get($outer-post-gutters, $name);
        padding-left: map-get($outer-post-gutters, $name);
      } @else {
        width: map-get($main-col-post-widths, $name) - $navWidth;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.post__separator {
  height: 1px;
  margin: var(--spacing-13) auto -1px;
  padding: 0;
  background-color: var(--color__border);
  border: 0 none;

  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      @if (map-get($main-col-post-widths, $name) == 'fluid') {
        width: auto;
        margin-right: map-get($outer-post-gutters, $name);
        margin-left: map-get($outer-post-gutters, $name);
      } @else {
        width: map-get($main-col-post-widths, $name) - $navWidth;
      }
    }
  }
}

.post__anchor {
  /* margin-top:$header-h - 1px; */
  display: block;
  height: 60px;
  width: 100%;
  // background:red;
  position: absolute;
  top: -#{$header-h-small / 2};
  z-index: 0;
  pointer-events: none;
  visibility: hidden;

  @include breakpoint('small+') {
    top: -#{$header-h / 2};
  }
}

.cover ~ .post__anchor {
  top: -#{$header-h-small};

  @include breakpoint('small+') {
    top: -#{$header-h};
  }
}

.post__collections {
  margin-top: var(--spacing-10);
}


/* dont display the separator when there is a cover */
.cover ~ .post__separator {
  display: none;
}


/* Post - Headings */
.post h1 {
  @include f-heading-01;
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-13);
}

.post h2 {
  @include f-heading-02;
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-15);
}

.main {
  counter-reset: titleNumber;
}

/* Numbers before titles */
.post h2 {
  &:before {
    display: inline;
    font-size: 1em;
    content: counter(titleNumber);
    counter-increment: titleNumber;
    padding-right: var(--spacing-04);

    .page--hide-numbers & {
      display: none;
    }

    @include breakpoint('small+') {
      padding-right: var(--spacing-08);
    }
  }

  a {
    span {
      @include bordered(var(--color__text--light));
    }
  }
}

.post h3 {
  @include f-heading-03;
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-15);
}

.post h4 {
  @include f-heading-04;
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-14);
}

.post h5 {
  @include f-body-01-em;
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  margin-top: var(--spacing-13);
}

/* Post - Intro */
.post .intro {
  @include f-intro;
  // @include smart-margin($f-intro, $margin-top__intro);
}

.post__context {
  @include f-body-01;
  display: block;
  margin-top: var(--spacing-05);
  color: var(--color__text--light);
  white-space: nowrap;

  a {
    @include bordered(var(--color__text--light), var(--color__text));
    transition: color 0.35s ease;

    &:hover {
      color: var(--color__text);
    }
  }
}

/* Post - Text */
.post__text,
.post__embed.post__embed--small,
.post .intro {
  max-width: colspan-post(9, 'xlarge', 0px, $navWidth);
  word-break: break-word;
}

.post__text img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  line-height: 10em;
  color: var(--color__text--light);
  text-align: center;
  background-color: var(--color__border);
}

/*
  Low level container of blocks :
  Texts and grids
*/
.post-small-grid:not(.post-grid--media),
.post-grid:not(.post-grid--media),
.post-media-carousel,
.post__text,
.post__embed {
  margin-top: var(--spacing-10);
}

.post p,
.post .intro {
  @include f-body-01;
  margin-top: var(--spacing-10);
}


.post .post__text p:first-child,
.post .intro p:first-child {
  margin-top: 0;
}

.post pre {
  @include f-body-01;
  margin-top: var(--spacing-13);
  font-size: 0.85rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding: var(--spacing-06);
}

.post ol,
.post ul {
  li {
    @include f-body-01;

    + li {
      margin-top: var(--spacing-03);
    }
  }
}

/* Post links / underlines in texts */
.post {
  u {
    @include bordered(var(--color__text), false);
  }

  ul, ol, p {
    a {
      @include bordered;
      text-decoration: none;
      transition: color 0.35s ease;

      &:hover {
        color: var(--color__text--light);
      }
    }
  }
}

/* Post - Ordered Lists */
$indentList: 24px;
$indentNumberedList: 24px;

.post ol, .post ul {
  margin-top: var(--spacing-06);

  li {
    list-style-type: inherit;
  }
}

.post ul {
  padding-left: $indentList;

  li {
    position: relative;
  }

  li:before {
    position: absolute;
    font-size: 0.66em;
    left: -#{$indentList};
    content: "\25CF";
  }

  ul li:before {
    content: "\25CB";
  }

  ul li li:before {
    content: "\25C6";
  }

  ul li li li:before {
    content: "\25C7";
  }
}

/* Post - Ordered Lists from Quill.js */
.post ul > li.ql-indent-1 {
  margin-left: $indentList;

  &:before {
    content: "\25CB";
  }
}

.post ul > li.ql-indent-2 {
  margin-left: $indentList * 2;

  &:before {
    content: "\25C6";
  }
}

.post ul > li.ql-indent-3 {
  margin-left: $indentList * 3;

  &:before {
    content: "\25C7";
  }
}

.post ul > li.ql-indent-4 {
  margin-left: $indentList * 3;

  &:before {
    content: "\25C7";
  }
}


/* Numbered lists */
.post ol {
  counter-reset: listNumber;
  padding-left: $indentNumberedList;

  li {
    position: relative;
    counter-reset: listNumber-2 listNumber-3 listNumber-4;
  }

  li:before {
    position: absolute;
    font-size: 1em;
    left: -#{$indentNumberedList};
    content: counter(listNumber) " ";
    counter-increment: listNumber;
  }

  ol {
    counter-reset: listNumber-2;
    padding-left: $indentNumberedList + 10px;
  }

  ol li {
    counter-reset: listNumber-3 listNumber-4;
  }

  ol li:before {
    left: -#{$indentNumberedList + 10px};
    content: counter(listNumber) "." counter(listNumber-2) " ";
    counter-increment: listNumber-2;
  }

  ol ol {
    counter-reset: listNumber-3;
    padding-left: $indentNumberedList + 10px + 10px;
  }

  ol ol li {
    counter-reset: listNumber-4;
  }

  ol ol li:before {
    left: -#{$indentNumberedList + 10px + 10px};
    content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) " ";
    counter-increment: listNumber-3;
  }


  ol ol ol {
    counter-reset: listNumber-4;
    padding-left: $indentNumberedList + 10px + 10px + 10px;
  }

  ol ol ol li {
    counter-reset: listNumber-5;
  }

  ol ol ol li:before {
    left: -#{$indentNumberedList + 10px + 10px + 10px};
    content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) "." counter(listNumber-4) " ";
    counter-increment: listNumber-4;
  }
}

/* Post - Numbered Lists from Quill.js */
.post ol li {
  counter-reset: listNumber-2 listNumber-3 listNumber-4;

  &:before {
    left: -#{$indentNumberedList};
    content: counter(listNumber) " ";
    counter-increment: listNumber;
  }
}

.post ol li.ql-indent-1 {
  margin-left: $indentNumberedList + 10px;
  counter-reset: listNumber-3 listNumber-4;

  &:before {
    counter-increment: listNumber-2;
    left: -#{$indentNumberedList + 10px};
    content: counter(listNumber) "." counter(listNumber-2) " ";
    counter-increment: listNumber-2;
  }
}

.post ol li.ql-indent-2 {
  margin-left: $indentNumberedList * 2 + 10px + 10px;
  counter-reset: listNumber-4;

  &:before {
    left: -#{$indentNumberedList + 10px + 10px};
    content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) " ";
    counter-increment: listNumber-3;
  }
}

.post ol li.ql-indent-3 {
  margin-left: $indentNumberedList * 3 + 10px + 10px + 10px;
  counter-reset: listNumber-5;

  &:before {
    counter-increment: listNumber-4;
    left: -#{$indentNumberedList + 10px + 10px + 10px};
    content: counter(listNumber) "." counter(listNumber-2) "." counter(listNumber-3) "." counter(listNumber-4) " ";
    counter-increment: listNumber-4;
  }
}

/* Italic */
.post i,
.post em {
  font-style: italic;
}

/* Bold */
.post b,
.post strong {
  @include f-body-01-em;
}

/* Quote */

.post blockquote {
  margin-top: var(--spacing-10);
  @include f-body-01-em;
  font-style: italic;
}

/* mark for search */
.post mark {
  color: inherit;
  border-radius: 2px;
  background-color: var(--color__text--highlight);
}

/* Invisible mark */
.post .post__result mark,
.post .post__results mark,
.post h1 a mark,
.post h2 a mark,
.post h3 a mark {
  color: inherit;
  background-color: transparent;
}

/* code blocks */
.post pre {
  @include f-body-02;
  font-size: 0.85rem;
  line-height: 1.2rem;
  font-weight: 400;
  // @include smart-margin($f-nav, $margin-top__body);
  padding: var(--spacing-06);
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  color: var(--color__text--on-dark);
  background-color: var(--color__background--dark);
  border-radius: 3px;
  overflow: auto;
}

/* Search results */
.post__results {
  color: var(--color__text--light);

  ol, ul {
    li {
      @include f-body-01;
      // @include smart-margin($f-body, $margin-top__results);

      ul {
        // @include tuck-reserve-top($f-body);
      }
    }
  }
}


/* Margins rules */
.post {
  .post-grid.post-grid--media + .post__text,
  .post-media-carousel + .post__text,
  .media + .post__text,
  h2 + .post__text {
    margin-top: var(--spacing-12);
  }
}

.post {
  h2 + .post__text,
  h3 + .post__text,
  h3 + .post-media-carousel,
  h2 + p,
  h3 + p,
  h2 + .intro,
  h3 + .intro {
    margin-top: var(--spacing-12);
  }
}

.post-grid__item {
  h3 + .post__text,
  h4 + .post__text,
  p + pre {
    margin-top: var(--spacing-10);
  }

  * + h4,
  * + h5 {
    margin-top: var(--spacing-12);
  }
}

.post-grid.post-text-grid {
  margin-top: var(--spacing-12);
}

.post-grid__item {
  h4, h5 {
    margin-top: 0;
  }
}

* + .colors-palette {
  margin-top: var(--spacing-05);
}
.post-grid + .colors-palette {
  margin-top: 0;
}


/* No numbers in post search results */
.searchResults .post {
  h2 {
    &:before {
      display: none;
    }
  }
}

/* Tables */
.post {
  .post__table {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin-top: var(--spacing-10);
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border-right: 1px solid var(--color__border);
  }

  table {
    width: 100%;
    border: 1px solid var(--color__border);
    border-right: none;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
    background: var(--color__background);

    tbody {
      width: 100%;
    }

    th {
      background-color: var(--color__background);

      p {
        @include f-body-02;
      }
    }

    tr {
      border-bottom: 1px solid var(--color__border);
    }

    th,
    td {
      border-left: 1px solid var(--color__border);
      padding: var(--spacing-04) var(--spacing-04);
      text-align: left;
      min-width: 200px;
      width: 25%;

      p {
        margin-top: 0;
      }
    }

    tr {
      &:first-child {
        th,
        td {
          p {
            font-weight: 600;
          }
        }
      }
    }
  }
}

/* Post - Text spacings */
.post__text {
  h4 + *,
  h5 + * {
    margin-top: var(--spacing-10);
  }
}

/* Videos */
.post {
  .post__video,
  .post__vimeo {
    position: relative;

    [data-behavior='toggleVideo'], [data-behavior='toggleVideoSound'] {
      display: flex;
      opacity: 0;
      transition: opacity 0.35s ease;
    }

    &:hover {
      [data-behavior='toggleVideo'], [data-behavior='toggleVideoSound'] {
        opacity: 1;
      }
    }
  }
}
