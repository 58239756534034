.panel {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateY(0);
}

.panel__content {
  bottom: 0;
  background-color: var(--color__overlay--light);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: $z-index-panel;
}

.panel__wrapper {
  position: relative;
  padding-top: var(--spacing-08);
  overflow: hidden;
}

.panel__header {
  height: $header-h-small;
  text-align: center;
  background-color: var(--color__overlay--light);
  background-clip: padding-box;
  border-bottom: 1px solid var(--color__border);
  cursor: pointer;
  z-index: $z-index-panel + 1;

  @include breakpoint('large+') {
    height: $header-h;
  }
}

.panel__close {
  -webkit-appearance: none;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: 40px;
  color: var(--color__text);
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
  transition: color 0.35s ease, border-color 0.35s ease;

  .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    color: var(--color__text);
    border-color: var(--color__text);
  }

  &:active {
    outline: 0;
  }
}
