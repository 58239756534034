/*
These classes, selectors will be overwrited per clients settings.
All classes are prefixed by 'theme-'
*/

/* Links Color */
.theme-link,
.theme-link--over {
  transition: color 0.35s ease;
}

.theme-link,
.theme-link--over:hover,
a:hover .theme-link--over {
  color: var(--color__brand);
}

.theme-link--overtag:hover {
  color: var(--color__background);
  background-color: var(--color__brand);
  transition: background-color 0.35s ease, color 0.35s ease;
}

.theme-link--border {
  border-color: var(--color__brand);
}

.theme-navbar {
  background-color: var(--color__background);

  .theme-link--over:hover {
    color: var(--color__text--light);

    &.theme-link {
      color: var(--color__brand);
    }
  }

  .theme-link--over.theme-link.link-active {
    color: var(--color__text);
    font-weight: 600;
  }
}

.theme-header {
  background-color: var(--color__background);

  .page-index &,
  .page-collection & {
    background-color: var(--color__background--dark);
    border-color: rgba(255, 255, 255, 0.15);

    .search__form {
      box-shadow: inset 0px -1px 0px 0px var(--color__border);
    }

    .header__logo,
    .dropdown > a,
    .search__toggle,
    .header__toggle {
      color: var(--color__text--on-dark);

      .icon--close {
        color: var(--color__text);
      }

      &:hover {
        color: var(--color__text--light);

        .icon--close {
          color: var(--color__text--light);
        }

        .icon--logo {
          color: var(--color__text--on-dark);
        }
      }
    }
  }
}

.hub__bg {
  .theme-link--over:hover {
    color: var(--color__text--light);
  }

  a:hover .theme-link--over {
    color: var(--color__text--light);
  }
}
