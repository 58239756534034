.cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 400px;
  max-height: 800px;
  margin-top: var(--spacing-13);

  @include breakpoint('small') {
    min-height: 600px;
  }

  @include breakpoint('medium') {
    min-height: 800px;
  }

  @include breakpoint('large+') {
    min-height: 800px;
  }

  @include breakpoint('small+') {
    margin-top: var(--spacing-14);
  }

  img {
    display: block;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 800px;
    margin: 0;
    object-position: 50% 50%;
    object-fit: cover;
    transform: translateX(-50%) translateY(-50%);
  }
}

.postContent .post:first-child {
  > .cover {
    margin-top: 0;

    @include breakpoint('small+') {
      margin-top: 0;
    }
  }
}

.cover__img {
  @include lazyloaded-picture;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color__border);
}

@include lazyload-animation(cover__img);
