.tag {
  @include f-body-02;
  box-sizing: border-box;
  display: inline-flex;
  padding: var(--spacing-01) var(--spacing-03);
  color: var(--color__text--light);
  white-space: nowrap;
  overflow: visible;
  border: 1px solid var(--color__text--light);
  border-radius: $border-radius-full;
}

.tag--greyed {
  padding: 0;
  border-color: transparent;
}
