$column-spans__small: (
    xsmall: 6,
    small: 6,
    medium: 6,
    large: 4,
    xlarge: 4
);

$column-spans__small-media: (
  xsmall: 6,
  small: 2,
  medium: 4,
  large: 4,
  xlarge: 4
);

.post-small-grid {
  @include columns-container-post-flex;
}

.post-small-grid__item {
  @include column-flex-post($column-spans__small);
}

.post-small-grid__item-media {
  @include column-flex-post($column-spans__small-media);
}

.post-small-grid {
  .media {
    margin-top: var(--spacing-10);
  }
}
