
$margin-top__hubTagline:(
  'xsmall' : 45,
  'small' : 45,
  'small+' : 55
);

.card {
  padding: var(--spacing-07) var(--spacing-06);
  display: block;
  width: 100%;
  color: var(--color__text);
  overflow: hidden;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    // background: linear-gradient(to bottom, var(--color__background) 0%, var(--color__background) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }

  .card__desc {
    @include f-body-02;
    margin-top: var(--spacing-05);

    @include breakpoint('medium+') {
      padding-right: 5%;
    }
  }
}

.card--disabled {
  opacity: 0.2;

  + .card__footer {
    opacity: 0.2;
  }
}

.card--featured {
  position: relative;
  height: auto;
  padding-bottom: 130px;
}

.card__footer {
  padding: var(--spacing-06);
  margin-top: auto;
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-04);
  align-items: center;

  a {
    position: relative;
    z-index: 2;
  }
}

.card__collections {
  flex-grow: 1;
}
