$column-spans__small: (
  xsmall: 6,
  small: 6,
  medium: 6,
  large: 4,
  xlarge: 4
);

$column-spans__mid: (
  xsmall: 6,
  small: 6,
  medium: 6,
  large: 6,
  xlarge: 6
);

.colors-palette.colors-palette-cols {
  @include columns-container-post-flex;

  .colors-palette-item {
    @include column-flex-post($column-spans__small);
  }

  &.colors-palette--cols-2 {
    .colors-palette-item {
      @include column-flex-post($column-spans__mid);
    }
  }
}

.colors-palette-item {
  border-radius: $border-radius-wide;
  height: fit-content;
  overflow: hidden;
  margin-top: 40px;

  @include breakpoint('small+') {
    padding-bottom: 0;
  }
}

.colors-palette-tint {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.colors-palette-tint__info {
  flex-grow: 1;
  padding-left: var(--spacing-05);
  padding-right: var(--spacing-05);
}

.colors-palette-tint--dark {
  color: var(--color__background);
}

.colors-palette-tint dl {
  @include f-caption;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-variant-numeric: lining-nums tabular-nums;
}

.colors-palette-tint dd {
  text-align: right;
}
