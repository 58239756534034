.footer {
  margin-top: auto;
  padding-top: var(--spacing-14);
  padding-bottom: var(--spacing-06);

  @include breakpoint('small+') {
    display: flex;
    padding-bottom: var(--spacing-08);

    .footer__copyright {
      flex-grow: 1;
    }
  }
}

.footer__copyright,
.footer__a17 {
  @include f-caption;
  padding: 0 var(--spacing-06);
  color: var(--color__text--light);

  @include breakpoint('small+') {
    padding: 0 var(--spacing-06);
  }
}

.footer__copyright {
  li {
    padding-bottom: var(--spacing-02);
  }

  li + li {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('small+') {
    li + li {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include breakpoint('medium+') {
    display: flex;

    li {
      flex-grow: 1;
      order: 1;
      // padding-left: var(--spacing-06);
      padding-bottom: 0;
    }

    li + li {
      order: 0;
      flex-grow: 0;
    }
  }
}

.footer__a17 {
  margin-top: auto;

  @include breakpoint('small+') {
    padding-left: 0;
  }
}

.footer__link:hover,
a:hover .footer__link {
  @include bordered(var(--color__text--light), false);
}
