.block-copy {
  max-width: colspan-post(9, 'xlarge', 0, $navWidth);
  margin-top: var(--spacing-13);

  .block-copy__list {
    margin-top: 0;
    padding-left: 0;

    .block-copy__variation-item {
      margin-top: 0;
      cursor: pointer;

      &[data-blockCopy-variation-selected] {
        color: var(--color__text);
        background-color: var(--color__background--ultra-light);
      }
    }
  }
}

.block-copy__header {
  display: flex;
  position: relative;
  align-items: center;
  padding: var(--spacing-05);
  background-color: var(--color__background--ultra-light);
  z-index: 2;

  @include breakpoint("small-") {
    align-items: flex-start;
  }
}

.block-copy__title {
  margin-right: auto;
}

.block-copy__body {
  position: relative;
  padding: var(--spacing-05);
  border: var(--spacing-02) solid var(--color__background--ultra-light);

  .block-copy__variation {
    display: none;

    &[data-blockCopy-variation-body-selected] {
      display: block;
    }

    p {
      margin-top: 0;

      + p {
        margin-top: var(--spacing-10);
      }
    }
  }
}

.block-copy__target {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}


.block-copy-dropdown {
  position: relative;
  margin-left: var(--spacing-05);
}

.block-copy__variation-select {
  padding-right: var(--spacing-02);
  border-right: 1px solid var(--color__background--medium);
}

.block-copy-dropdown__content {
  position: absolute;
}

.block-copy__label,
.block-copy__label-icon {
  display: flex;
  align-items: center;
}

.block-copy__label {
  @include btn-reset;
}

.block-copy__btn {
  @include btn-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: var(--spacing-02);
  transition: color 300ms ease-out;

  &:hover {
    color: var(--color__text--light);
  }
}

.block-copy__alert {
  @include f-body-01;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--spacing-05);
  text-align: center;
  background-color: var(--color__background--error);
  z-index: $z-index-alert;
  transform: translateY(100%);
  transition: transform 300ms ease-out;

  &[data-blockCopy-success-reveal] {
    transform: translateY(0);
  }
}
