/* spacing vertical & horizontal */
$headerPost-small-h: $header-h-small - $header-small-v-pad - $header-small-v-pad;
$headerPost-h: $header-h - $header-v-pad - $header-v-pad;

.header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  background: var(--color__background);
  background-clip: padding-box;
  border-bottom: 1px solid var(--color__border);
  overflow: visible;
  z-index: $z-index-header;
}

.header__logo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  max-width: $navWidth;
  padding: $header-small-v-pad 0 #{$header-small-v-pad - 1px} $header-small-h-pad;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.35s ease;

  @include breakpoint('large+') {
    height: $header-h - 1px;
    padding: $header-v-pad 0 $header-v-pad $header-h-pad;
  }
}

.header__right {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  padding-right: $header-small-h-pad;

  @include breakpoint('large+') {
    padding-right: $header-h-pad;
  }
}

.logo--desktop {
  display: none;

  @include breakpoint('large+') {
    display: block;
  }
}

.logo--mobile {
  display: block;

  @include breakpoint('large+') {
    display: none;
  }
}

.header__nav {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
  overflow: visible;
}

.header__btn {
  -webkit-appearance: none;
  position: relative;
  display: inline-block;
  height: $headerPost-small-h;
  padding: 0;
  text-transform: uppercase;
  line-height: $headerPost-small-h;
  font-family: inherit;
  font-size: inherit;
  color: var(--color__text--light);
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  transition: color 0.35s ease;

  &:hover {
    color: var(--color__text);
  }

  &:active {
    outline: 0;
  }

  .icon {
    display: block;
  }

  @include breakpoint('xsmall') {
    display: none;
  }

  @include breakpoint('large+') {
    height: $headerPost-h;
    line-height: $headerPost-h;
  }
}

.header__home {
  display: block;
  height: $headerPost-small-h;
  line-height: $headerPost-small-h - 1px;

  @include breakpoint('large+') {
    height: $headerPost-h;
    line-height: $headerPost-h - 1px;
  }
}

.header__home {
  display: flex;
  align-items: center;
}

.header__toggle {
  @include btn-reset;
  position: relative;
  margin-left: var(--spacing-04);
  color: var(--color__text);
  transition: color 0.3s ease;

  &:hover {
    color: var(--color__text--light);
  }

  @include breakpoint('large+') {
    display: none;
  }
}

.header__loader {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--color__text);
}
